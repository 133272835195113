var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title"),
      _c("div", { staticStyle: { margin: "30px" } }, [
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "20px",
              color: "blue",
              "padding-right": "20px"
            }
          },
          [_vm._v(_vm._s(_vm.empName))]
        ),
        _c("span", { staticStyle: { "font-size": "14px", color: "gray" } }, [
          _vm._v("登陆时间：" + _vm._s(_vm.visitAt))
        ])
      ]),
      _c("div", { staticClass: "partition-area" }, [
        _c(
          "div",
          { staticClass: "tabs-inner message-list" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeName,
                  callback: function($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName"
                }
              },
              [
                _c(
                  "table-inner",
                  {
                    staticClass: "customer-no-border-table",
                    attrs: {
                      title: "未读消息",
                      "table-data": _vm.messageList.list,
                      "page-num": _vm.messageList.pageNum,
                      "page-size": _vm.messageList.pageSize,
                      pages: _vm.messageList.pages,
                      "page-id": 1,
                      "table-footer": false
                    },
                    on: {
                      changePageNum: function($event) {
                        return _vm.changePageNum($event)
                      }
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "btn-inner" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.moreMessage }
                          },
                          [_vm._v(" 更多 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "table-bd" },
                      [
                        _c(
                          "el-table",
                          { attrs: { stripe: "", data: _vm.messageList.list } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "informationContent",
                                formatter: _vm.utils.isEffective,
                                "show-overflow-tooltip": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "message-item",
                                          class:
                                            scope.row.status !== 0
                                              ? "read"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.read(
                                                scope.row,
                                                scope.$index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          scope.row.status === 0
                                            ? _c("img", {
                                                staticClass: "message-icon",
                                                attrs: {
                                                  src: require("../../../assets/images/message.png")
                                                }
                                              })
                                            : _vm._e(),
                                          scope.row.status !== 0
                                            ? _c("img", {
                                                staticClass: "message-icon",
                                                attrs: {
                                                  src: require("../../../assets/images/message-open.png")
                                                }
                                              })
                                            : _vm._e(),
                                          _c("div", [
                                            scope.row.status === 0
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:;"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goPage(
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "show_length"
                                                        },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.utils.formatHtmlLabel(
                                                                  scope.row
                                                                    .informationContent
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticStyle: {
                                                            "margin-top": "5px",
                                                            "margin-left":
                                                              "10px"
                                                          }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "发送时间: " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .createdAt
                                                                )
                                                            )
                                                          ]),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "40px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "消息类型: " +
                                                                  _vm._s(
                                                                    _vm.utils.formatMessageType(
                                                                      Number(
                                                                        scope
                                                                          .row
                                                                          .informationType
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "90px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "创建人: " +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .createdBy
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            scope.row.status !== 0
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      opacity: "0.5"
                                                    },
                                                    attrs: {
                                                      href: "javascript:;"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goPage(
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "show_length"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.utils.formatHtmlLabel(
                                                                scope.row
                                                                  .informationContent
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c("p", [
                                                        _c("span", [
                                                          _vm._v(
                                                            " 发送时间: " +
                                                              _vm._s(
                                                                scope.row
                                                                  .createdAt
                                                              )
                                                          )
                                                        ]),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "40px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "消息类型: " +
                                                                _vm._s(
                                                                  _vm.utils.formatMessageType(
                                                                    Number(
                                                                      scope.row
                                                                        .informationType
                                                                    )
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "90px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "创建人: " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .createdBy
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tabs-inner" },
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: {
                  "tab-click": function($event) {
                    return _vm.getTaskList()
                  }
                },
                model: {
                  value: _vm.activeName,
                  callback: function($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "待办事宜", name: "wait" } },
                  [
                    _c("ics-task-list-inner", {
                      attrs: {
                        loading: _vm.loading.list,
                        "label-name": _vm.activeName,
                        "table-list": _vm.tableList
                      },
                      on: { search: _vm.getTaskList }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "已办事宜", name: "complete" } },
                  [
                    _c("ics-task-list-inner", {
                      attrs: {
                        loading: _vm.loading.list,
                        "label-name": _vm.activeName,
                        "table-list": _vm.tableList,
                        "view-mode": ""
                      },
                      on: { search: _vm.getTaskList }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }