<template>
  <div>
    <search-inner
      @submit-search="search"
      @clear-search="reset"
    >
      <el-col :span="6">
        <el-form-item :label="`${viewLabelName}编码：`">
          <el-input v-model="searchForm.code" :placeholder="`请输入要搜索的${viewLabelName}编码`" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item :label="`${viewLabelName}类型：`">
          <el-input v-model="searchForm.dataText2" :placeholder="`请输入要搜索的${viewLabelName}类型`" />
        </el-form-item>
      </el-col>
    </search-inner>
    <table-inner v-loading="loading" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" @changePageNum="changePageNum">
      <template slot="table-columns">
        <el-table-column type="index" label="序号" align="center" show-overflow-tooltip min-width="100" />
        <el-table-column prop="code" :label="`${viewLabelName}编号`" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="description" :label="`${viewLabelName}事项`" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="230" />
        <el-table-column prop="typeName" :label="`类型`" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="createdAt" label="产生时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column label="操作" fixed="right" align="center" show-overflow-tooltip min-width="100">
          <template slot-scope="scope">
            <a v-if="!viewMode" href="javascript:" class="text-btn" @click="audit(scope.row)">前去处理</a>
            <a v-if="labelName !== 'wait'" href="javascript:" class="text-btn" @click="view(scope.row)">查看</a>
          </template>
        </el-table-column>
      </template>
    </table-inner>
  </div>
</template>

<script>
export default {
  name: 'IcsTaskListInner',
  props: {
    tableList: {
      type: Object,
      default () {
        return {
          list: []
        }
      }
    },
    labelName: {
      type: String,
      default: ''
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchForm: {
        code: '',
        dataText2: ''
      },
      viewLabelName: ''
    }
  },
  watch: {
    labelName: {
      handler (val) {
        if (val) {
          if (val === 'wait') {
            this.viewLabelName = '待办'
          } else if (val === 'complete') {
            this.viewLabelName = '已办'
          } else {
            this.viewLabelName = ''
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    search () {
      this.$emit('search', this.searchForm)
    },
    reset () {
      this.searchForm.code = ''
      this.searchForm.dataText2 = ''
      this.$emit('search', this.searchForm)
    },
    changePageNum ({ pageNum }) {
      this.searchForm.pageNum = pageNum
      this.$emit('search', this.searchForm)
    },
    audit (row) {
      this.urlParser(row)
    },
    view (row) {
      this.urlParser(row, 'view')
    },
    urlParser (data, type) {
      console.log(data, type)
      if (type !== 'view') {
        if (data.dataType === '100' || data.dataType === '101') {
          return this.$router.push({ path: '/creditManagement', query: { creditCode: data.dataCode } })
        } else if (data.dataType === '200' || data.dataType === '201') {
          return this.$router.push({ path: '/useManagement', query: { useCode: data.dataCode } })
        } else if (data.dataType === '400' || data.dataType === '401') {
          return this.$router.push({ path: '/contractManagement/sign', query: { contractId: data.dataCode } })
        } else {
          return false
        }
      } else {
        if (data.dataType === '100' || data.dataType === '101') {
          return this.$router.push({ path: '/creditManagement', query: { creditCode: data.dataCode } })
        } else if (data.dataType === '200' || data.dataType === '201') {
          return this.$router.push({ path: '/useManagement', query: { useCode: data.dataCode } })
        } else if (data.dataType === '400' || data.dataType === '401') {
          return this.$router.push({ path: '/contractManagement/sign', query: { contractId: data.dataCode } })
        } else {
          return false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
