var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "search-inner",
        { on: { "submit-search": _vm.search, "clear-search": _vm.reset } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.viewLabelName + "编码：" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入要搜索的" + _vm.viewLabelName + "编码"
                    },
                    model: {
                      value: _vm.searchForm.code,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "code", $$v)
                      },
                      expression: "searchForm.code"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.viewLabelName + "类型：" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入要搜索的" + _vm.viewLabelName + "类型"
                    },
                    model: {
                      value: _vm.searchForm.dataText2,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "dataText2", $$v)
                      },
                      expression: "searchForm.dataText2"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "table-inner",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "table-data": _vm.tableList.list,
            "page-num": _vm.tableList.pageNum,
            "page-size": _vm.tableList.pageSize,
            pages: _vm.tableList.pages
          },
          on: { changePageNum: _vm.changePageNum }
        },
        [
          _c(
            "template",
            { slot: "table-columns" },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "100"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "code",
                  label: _vm.viewLabelName + "编号",
                  align: "center",
                  formatter: _vm.utils.isEffective,
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "description",
                  label: _vm.viewLabelName + "事项",
                  align: "center",
                  formatter: _vm.utils.isEffective,
                  "show-overflow-tooltip": "",
                  "min-width": "230"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "typeName",
                  label: "类型",
                  align: "center",
                  formatter: _vm.utils.isEffective,
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createdAt",
                  label: "产生时间",
                  align: "center",
                  formatter: _vm.utils.isEffective,
                  "show-overflow-tooltip": "",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !_vm.viewMode
                          ? _c(
                              "a",
                              {
                                staticClass: "text-btn",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function($event) {
                                    return _vm.audit(scope.row)
                                  }
                                }
                              },
                              [_vm._v("前去处理")]
                            )
                          : _vm._e(),
                        _vm.labelName !== "wait"
                          ? _c(
                              "a",
                              {
                                staticClass: "text-btn",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function($event) {
                                    return _vm.view(scope.row)
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }